





















































































































































































































.layout-padding
  @media (max-width: 1199px)
    padding 1em
.content-around
  position relative
  .q-inner-loading
    background: transparent
    height: 200px
.infoCard
  background-color white
  box-shadow none
.footer
  padding 16px
  padding-top 4px
  border 1px solid rgba(black, 0.125)
  *
    margin-top 8px
    margin-bottom 8px
