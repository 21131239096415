

























































.route-map
  height: calc(100vh - 64px);
  width: 100%;
  transition: height 0.3s;
  @media (max-width 768px)
    height 60vh
