































































































































.hero
  min-height 100px
  color rgba(255, 255, 255, 1)
  overflow hidden
  background-position 0 35%
  background-size cover
  text-overflow: clip;

  @media (min-width: 768px)
    padding 32px
    min-height 240px

h5
  @media (max-width 768px)
    font-size 20px
    line-height 1

h3
  @media (max-width 768px)
    font-size 24px
    line-height 1

.q-tabs
  top initial
  width 80%
  max-width 768px
  z-index 1

.layout-padding
  max-width 1024px
