.hero {
  min-height: 100px;
  color: #fff;
  overflow: hidden;
  background-position: 0 35%;
  background-size: cover;
  text-overflow: clip;
}
@media (min-width: 768px) {
  .hero {
    padding: 32px;
    min-height: 240px;
  }
}
@media (max-width: 768px) {
  h5 {
    font-size: 20px;
    line-height: 1;
  }
}
@media (max-width: 768px) {
  h3 {
    font-size: 24px;
    line-height: 1;
  }
}
.q-tabs {
  top: initial;
  width: 80%;
  max-width: 768px;
  z-index: 1;
}
.layout-padding {
  max-width: 1024px;
}
/*# sourceMappingURL=src/pages/bookings/user-bookings/show/index.css.map */