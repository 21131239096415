


























































.layout-padding
  @media (max-width: 1199px)
    padding 1em
