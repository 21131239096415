






































.documentCard
  background-color darken(white, 10)
  box-shadow 0 2px 4px -1px rgba(0,0,0,0.1), 0 4px 5px rgba(0,0,0,0.07), 0 1px 10px rgba(0,0,0,0.06)
