
















.status-box
  background white
  height 350px
  width 250px
  position absolute
  top 0
  right 0
  margin 20px
  padding 5px 10px
  overflow-y auto
  border-radius 25px
  border 2px solid convert(brandColor)

.status-text
  padding-top 5px

.q-separator
  margin-top 5px
