
























.index-list
  padding 3rem 3rem 0 3rem
  height calc(100vh - 600px)
.row-item
  width 100%
  margin auto
