@media (max-width: 1199px) {
  .layout-padding {
    padding: 1em;
  }
}
.content-around {
  position: relative;
}
.content-around .q-inner-loading {
  background: transparent;
  height: 200px;
}
.infoCard {
  background-color: #fff;
  box-shadow: none;
}
.footer {
  padding: 16px;
  padding-top: 4px;
  border: 1px solid rgba(0,0,0,0.125);
}
.footer * {
  margin-top: 8px;
  margin-bottom: 8px;
}
/*# sourceMappingURL=src/pages/bookings/user-bookings/show/details/index.css.map */