

















.layout-padding
  max-width 1024px
.text-uppercase
  text-transform text-uppercase
