.status-box {
  background: #fff;
  height: 350px;
  width: 250px;
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
  padding: 5px 10px;
  overflow-y: auto;
  border-radius: 25px;
  border: 2px solid #406e8e;
}
.status-text {
  padding-top: 5px;
}
.q-separator {
  margin-top: 5px;
}
/*# sourceMappingURL=src/pages/bookings/backoffice-bookings/status/status-box.css.map */